import React from "react";
import "./Footer.css"; // Import the CSS file for styling
import { FaLinkedinIn } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { FOOTERS } from "../../Constants/Constants";
const Footer = () => {
  const location = useLocation();
  const pathLocation = ["/login", "/register", "/undermaintainance", "/mobile-maintainance"];

  const loginAndRegister = pathLocation.includes(location.pathname);

  if (!loginAndRegister)
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo" style={{marginTop:"1em"}}>
            <img src="../images/Logo.svg" alt="" />
          </div>

          <div className="company-address">
            <div className="address-one">
              <p>Operating office:</p>
              <p>1743, HSR Layout Sector 2, Bangalore Karnataka 560102</p>
            </div>

            <div className="address-two">
              <p>Head Office:</p>
              <a href="https://ostontechnology.com/home">
                Oston Technology Pvt. Ltd.
              </a>
              <p>45, Champapura, Kalwar road, Jaipur, Rajasthan 303706 INDIA</p>
            </div>
          </div>

          <div className="pages">
            <ul>
              {FOOTERS.map((tab,index) => (
                <li key={index}>
                  <Link className="footer-pages" to={tab.path}>
                    {tab.tag}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer-socials-links">
            <p>Follow Us</p>
            <div className="socials-links">
              {/* <i className="fa-brands fa-instagram"></i> */}
              {/* <i className="fa-brands fa-linkedin-in"></i> */}
              <a href="https://www.linkedin.com/company/96441539/">
                <FaLinkedinIn />
              </a>
              {/* <i className="fa-brands fa-twitter"></i> */}
              {/* <i className="fa-brands fa-facebook"></i> */}
              {/* <a href="https://api.whatsapp.com/send?phone=919929128083&text=i+am+interested+in+mentorship+course">
              <i className="fa-brands fa-whatsapp"></i>
              </a> */}
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>&copy; {new Date().getFullYear()} by bytecoder and  Crafted By: Oston Technology Pvt Ltd</p>
          
        </div>
      </footer>
    );
};

export default Footer;
