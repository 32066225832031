import React, { useEffect, useState } from "react";
import "./App.css";
import LandingPage from "./LandingPages/LandingPage";
import { register } from 'swiper/element/bundle';

function App() {
    register();
    return (
        <LandingPage />
    );
  

}

export default App;
