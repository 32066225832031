import "./Partners.scss";

import * as PartnerApi from "../../../service/OurMembersAndTestimonialsApi.js";
import { useEffect, useState } from "react";

function Partners() {
  const [placementCompanies, setPlacementCompanies] = useState([
    {
      name: "",
      logoUrl: "/images/oston.png",
    },
    {
      name: "",
      logoUrl: "/images/ospill.png",
    },
    {
      name: "",
      logoUrl: "/images/smarttrak.png",
    },
    {
      name: "",
      logoUrl: "/images/gurukripa.png",
    },
    //  {
    //   name:"",
    //   logoUrl:"/images/webknot.png"
    // }
  ]);

  return (
    <div className="partner-section">
      <h1>Our Partners</h1>

      <div className="marquee">
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default Partners;
