import * as Congs from './Configurations'



export function getAllTestimonials()  {
  console.log("fetching courses")
  return  fetch(Congs.GET_ALL_TESTIMONIALS, Congs.HEADERS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


export function getAllPlacementCompanies()  {
  console.log("fetching courses")
  return  fetch(Congs.GET_ALL_PLACEMENT_COMPANIES, Congs.HEADERS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


export function getAllStats()  {
  console.log("fetching courses")
  return  fetch(Congs.GET_ALL_STATS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};

export function getAllTeamMembers()  {
  console.log("fetching courses")
  return  fetch(Congs.GET_ALL_TEAM_MEMBERS, Congs.HEADERS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


