import React, { useEffect } from "react";
import "./UnderMaintainance.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SuccessRegistration = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    toast.success("Redirecting to home page.");
    setTimeout(() => {
      navigate("/home");
    }, 10000);
  },[])
  
  return (
    <div style={{position: "relative", overflow:"hidden"}}>

        <div className="red-circle"></div>
        <div className="green-circle"></div>

      <div className="undermaintainance">
        <div className="image-and-heading">
          <img src="/images/Logo.svg" alt="" />
          <h3>We'll be back</h3>
          <p>
            Thank for registering with us.Soon our team will connect with you 
          </p>
        </div>

        <div className="robot-img">
          <img src="images/robot-img.svg" alt="" />
        </div>
        <ToastContainer />

      </div>
    </div>
  );
};

export default SuccessRegistration;
