import React from "react";
import "./AwardCard.scss";

function AwardCard({image,title}) {
  return (
    <div
      className="award-card"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="overlay"></div>
      <div className="card-content">
        <img src="/images/award-logo.png" alt="" className="hover-image" />
        {/* <p>{title}</p> */}
      </div>
    </div>
  );
}

export default AwardCard;
