

export function Banner() {

    return (
        <div className="JobsBanner">
            <div
                className="career-head"
                style={{ margin: "4rem 0", position: "relative" }}
            >
                <h2 style={{ fontSize: "1.9rem" }}>Careers</h2>
                <p className="career-head-para">
                    Explore and apply for the exponential <br /> opportunities at
                    byteCoder
                </p>

                <div className="lamp">
                    <img src="/images/lamp.svg" alt="" />
                </div>
            </div>
            {/* Company Images */}
            <div className="company-images">
                <div className="first-images-container">
                    <div className="first-image">
                        <img src="/images/first-image.svg" alt="" />
                    </div>
                    <div className="first-images">
                        <img
                            className="first-one"
                            style={{
                                objectFit: "cover",
                            }}
                            src="/images/second-image.svg"
                            alt=""
                        />
                        <img className="first-two" src="/images/third-image.svg" alt="" />
                    </div>
                </div>

                <div className="second-images-container">
                    <div className="second-image">
                        <img src="/images/working-emp.svg" alt="" />
                    </div>
                    <div className="second-images">
                        <img
                            className="second-images-1"
                            src="/images/right-image.svg"
                            alt=""
                        />
                        <img
                            className="second-images-2"
                            src="/images/right-image-2.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}