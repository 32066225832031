import {Navigate, 
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import React, { useState } from "react";
import Home from "./HomePage/HomePage";
import Login from "./Login/Login";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Services from "./ServicesPage/ServicesPage";
import Programs from "./ProgramsPage/ProgramsPage";
import Careers from "./CareersPage/CareersPage";
import CareerDetailsPage from "./CareersPage/CareerDetailPage/CareerDetailsPage";
import AboutUs from "./AboutUsPage/AboutUsPage";
import CourseProgramDetailsPage from "./ProgramsPage/CourseDetails/CourseProgramDetailsPage";
import BlogsCardDetails from "./BlogsCardPage/BlogsPage";
import BlogDetailsPage from "./BlogsCardPage/ReadBlog/BlogDetailsPage";
import { HEADERS } from "../Constants/Constants";
import Register from "./Register/Register";
import UnderMaintainance from "./MaintainancePages/UnderMaintainance";
import MobileMaintainance from "./MaintainancePages/MobileMaintainance";
import SuccessRegistration from "./MaintainancePages/RegistrationSuccess";

export default function LandingPage(props) {
  

  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/programs" exact element={<Programs />} />
          <Route
            path="/programs/:courseCode/"
            exact
            element={<CourseProgramDetailsPage />}
          />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/careers" exact element={<Careers />} />
          <Route path="/careers-details" exact element={<CareerDetailsPage />} />
          <Route path="/blogs" exact element={<BlogsCardDetails />} />
          <Route path="/blogs/:id" exact element={<BlogDetailsPage />} />
          
          <Route path="/register" element={<Register />} />
          <Route  path="/login" exact element={<Login />}/>

          <Route path="/undermaintainance" element={<UnderMaintainance />} />
          <Route path="/success-registration" element={<SuccessRegistration />} />
          <Route path="/mobile-maintainance" element={<MobileMaintainance />} />
         
          <Route path="*" element={<Navigate to="/" replace />} />    
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
