import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { TextField, Button, Paper } from "@mui/material";
import "./CSS/Button.css";
import { Link, useNavigate } from "react-router-dom";

export function SelectButton(props) {
  let link = props.link;
  return (
    <div>
      <Chip
        color={props.color}
        value={link}
        label={link}
        variant="outlined"
        onClick={() => props.handleClick(link)}
      />
    </div>
  );
}




export function JoinButton() {
  return (
    <div className="green">
      <Link style={{ cursor: "pointer" }} to={"/login"}>
        <button>Join</button>
      </Link>
    </div>
  );
}
