import React, { useState, useEffect } from "react";
import "./ProgramsPage.css";
import CourseCard from "../../Molecule/CourseCard/CourseCard";


import { Link, useNavigate } from "react-router-dom";
import { SearchComponent } from "./SearchComponent/SearchComponent";

export default function Programs() {
  const [courses, setCourses] = useState([])

  const navigation = useNavigate();

  const openCourse = (code) => {
    navigation("/programs/" + code);
  };


  return (
    <div className="program-container">
      <SearchComponent setCourses={setCourses} />
      <div className="programGrid">
        <div className="program-card-container">
          {courses.map((course) => {
            return (
              <CourseCard
                course={course}
                openCourse={() => {
                  openCourse(course.code);
                }}
              />
            );
          })}

        </div>
      </div>



    </div>
  );
}
