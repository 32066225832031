import React, {useEffect, useState} from 'react'
import SearchAndPopularTags from '../../../Atoms/SerachAndPopularTags'
import * as BlogsService from "../../../service/BlogsApi";
import './SearchComponent.scss'

export function SearchComponent(props) {
    let setBlogs  = props.setBlogs
    const [searchText, setSearchText] = useState("");
    const [populerShortcuts, setpopulerShortcuts] = useState([])
    const [interest, setInterest] = useState("")
  
  
    useEffect(() => {
      BlogsService.getPopulerShortcuts().then(shortcuts => setpopulerShortcuts(shortcuts))
  
    }, []);
  
    useEffect(() => {
      BlogsService.getWebsiteBlogs("", interest).then((response) =>
        setBlogs(response.response)
      );
  
    }, [interest]);
  
    const searchBlogs = (e) => {
      e.preventDefault()
      console.log(searchText)
      BlogsService.getWebsiteBlogs(searchText, interest).then((response) =>
        setBlogs(response.response)
      );
    }
    return (
        <div className='SearchComponent'>

            <div>
                <h1>Our Blogs</h1>
            </div>

            <div className="blog-desc">
                <p style={{}}>
                    Elevating Your Journey from Education <br />
                    to Employment
                </p>
            </div>

            <div className="globe">
                <img src="/images/globe2.svg" alt="" />
            </div>


            <SearchAndPopularTags props={{ interest, populerShortcuts, setInterest, searchText, setSearchText, searchEvent: searchBlogs }} />

        </div>
    )
}