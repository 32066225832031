import React, { useEffect, useState } from "react";
import './CourseCard.scss'
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CourseCard(props) {
  const [mediaType, setMediaType] = useState({ imageUrl: "" });

  useEffect(() => {
  }, []);
  const course = props.course;

 
  return (
    <div className="CourseCard">

    <div className="course-container">
      <div key={course.id} onClick={() => props.openCourse(course)}>
        <div className="course-img" style={{ width: "100%" }}>
          <div className="program-img">
            <img src={course.imageUrl} alt="/images/temp/default.png" />
          </div>
        </div>

        <div className="course-name-details">
          <h3 style={{ fontWeight: "bold", fontSize: "16px", margin: 0 }}>
            {course.title}
          </h3>
          <div className="course-price-and-time">
            <p>{course.duration}</p>
            <p>{course.price}</p>
          </div>
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default CourseCard;
