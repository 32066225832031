import React, { useState, useEffect } from "react";
import SearchAndPopularTags from '../../../Atoms/SerachAndPopularTags'
import * as CoursesApi from '../../../service/CoursesApi'
import { Link, useNavigate } from "react-router-dom";


export function SearchComponent(props) {
    let setCourses = props.setCourses
    const [searchText, setSearchText] = useState("");
    const [populerShortcuts, setpopulerShortcuts] = useState([])
    const [interest, setInterest] = useState("")

    useEffect(() => {
        CoursesApi.getAllCourses("", interest).then(cs => setCourses(cs))
    }, [interest])

    const searchCourse = (e) => {
        e.preventDefault()
        console.log("search " + searchText + " interest " + interest)
        CoursesApi.getAllCourses(searchText, interest).then(cs => setCourses(cs))
    }


    useEffect(() => {
        // CoursesApi.getAllCourses().then(cs =>setCourses(cs))
        CoursesApi.getPopulerShortcuts().then(shortcuts => setpopulerShortcuts(shortcuts))
    }, [])

    return (
        <div className="SearchComponent">
            <div className="prgram-top">
                <div className="girl-svg1">
                    <img src="/images/girlSvg.svg" alt="" />
                </div>

                <h2>
                    Our Placement <br /> Programs
                </h2>
                <p style={{ textAlign: "center" }}>
                    Elevating Your Journey from Education <br /> to Employment
                </p>

                <div className="bulb">
                    <img src="/images/bulb.svg" alt="" />
                </div>
            </div>
            <SearchAndPopularTags props={{ interest, populerShortcuts, setInterest, searchText, setSearchText, searchEvent: searchCourse }} />

        </div>
    )
}