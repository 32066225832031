// export const BASE_URL = "https://3.108.24.230/back-end"
// export const BASE_URL = "http://localhost:8000"
export const BASE_URL = "https://bytecoder.in/back-end";
export const HEADERS = {
    'Content-Type': 'application/json',
  }

export const GET_ALL_COURSES = BASE_URL+  "/api/v1/courses/"
export const GET_ALL_MENTORSHIP_COURSES = BASE_URL+  "/api/v1/mentorship-courses/"

export const GET_ALL_POPULER_SHORTCUTS = BASE_URL+  "/api/v1/courses/populer-shortcuts"
export const GET_ALL_COURSE_BY_CODE = BASE_URL+  "/api/v1/courses/"
export const GET_ALL_ENROLLED_COURSES = BASE_URL+  "/api/v1/enrolled-courses/"
export const GET_ALL_BLOGS = BASE_URL+  "/api/v1/blogs/"
export const GET_ALL_POPULER_BlOGS_SHORTCUTS = BASE_URL+  "/api/v1/blogs/populer-shortcuts"
export const GET_ALL_TESTIMONIALS = BASE_URL+ "/api/v1/placements/testimonials"
export const GET_ALL_PLACEMENT_COMPANIES = BASE_URL+ "/api/v1/placements/companies"
export const GET_ALL_TEAM_MEMBERS = BASE_URL+ "/api/v1/team-members"
export const GET_ALL_STATS = BASE_URL+  "/api/v1/placements/stats"
export const NEW_REGISTRATION = BASE_URL+  "/api/v1/users/register"
export const Login_USER = BASE_URL+  "/api/v1/login"