import React, { useEffect, useState } from "react";
import MentorshipCourses from "./Mentorhship/MentorshipCourses";
import "./HomePage.scss";
import TestimonialCard from "./Testimonials/TestimonialCard";
import { OurCourses } from "./OurCourses/OurCourses";
import { OurBlogs } from "./OurBlogs/OurBlogs";
import { HeroSection } from "./HeroSection/HeroSection";
import { PlacementStats } from "./PlacementStats/PlacementStats";
import Partners from "./Partners/Partners";
import ImpactSection from "./ImpactSection/ImpactSection";
import Services from "./Services/Services";
import AwardCard from "./AwardCard/AwardCard";
import ScrollableBanner from "./ScrollableBanner/ScrollableBanner";

const awards = [
  {
      id:1,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award1.png"
  },
  {
      id:2,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award2.webp"
  },
  {
      id:3,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award3.webp"
  },
  {
      id:4,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award4.webp"
  },
  {
      id:5,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award5.webp"
  },
  {
      id:6,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award6.webp"
  },
  {
      id:7,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award7.webp"
  },  {
      id:8,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award8.webp"
  },  {
      id:9,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award9.webp"
  },  {
      id:10,
      award_title:"Winner In Design Thinking IIM - Ahmedabad",
      award_img_url:"/images/award10.webp"
  },
];

export default function Home({ props }) {
  return (
    <div className="HomePage">
      <HeroSection />
      <ScrollableBanner />
      {/* <MentorshipCourses /> */}
      <ImpactSection />
      <PlacementStats />
      <OurCourses />
      <OurBlogs />
      <TestimonialCard />

      <section className="award-section">
        <h1>
          Celebrating Our <span>Achievements and Accolades</span>
        </h1>
        <div className="award-container">
          {awards.map((award) => (
            <AwardCard
              key={award.id}
              image={award.award_img_url}
              title={award.award_title}
            />
          ))}
        </div>
      </section>
      <Partners />
    </div>
  );
}
