import React, { useEffect, useState } from "react";

import "./PlacementStats.scss"

import * as OurMembersAndTestimonialsApi from "../../../service/OurMembersAndTestimonialsApi";

export function PlacementStats(){

   
    const [placementCompanies, setPlacementCompanies] = useState([]);
    const [stats, setStats] = useState({});
  
    useEffect(() => {
 
      OurMembersAndTestimonialsApi.getAllStats().then(st => setStats(st))
      OurMembersAndTestimonialsApi.getAllPlacementCompanies().then(companies => setPlacementCompanies(companies))
    }, []);

    

    return(
        <div className="PlacementStats">
                  <div className="our-vision">
        <div className="vision-data">
          <h2>Our Vision</h2>
          <p>
            We provide comprehensive and in-depth training on all subjects,
            ensuring our students gain practical knowledge that prepares them
            for their dream company. Our primary objective is to equip our
            students with industry-relevant skills and knowledge, making them
            job-ready upon completion of our program.
          </p>
          <b></b>
          <p>
            To gauge their progress and understanding, we conduct mock
            interviews and tests, allowing them to assess their performance and
            identify areas for improvement. Furthermore, we believe in hands-on
            learning, which is why we assign industry-based projects and
            assignments to enhance their practical skills.
          </p>
        </div>
      </div>

      <div className="placement-card">
        <div className="statistics">
          <h2>We Take Pride in Our Numbers</h2>
          <p style={{ width: "90vw", fontSize: "1rem", textAlign: 'center' }}>
            Leveraging the Transformative Potential of Statistics and Data for
            Unmatched Success and Growth
          </p>

          <div className="placement-svg">
            <img style={{ width: '100%' }} src='/images/temp/placementStats.svg' />
          </div>

        </div>
        <div className="alumni-works-at">

          <div className="alum-container">
            <h2>Our Alumni Works At</h2>
            <p style={{ textAlign: "center" }}>
              Our Alumni Work at Leading Global Companies: A Testimony to the
              Unwavering Dedication to Excellence and Unparalleled Career
              Advancement Opportunities Offered Here
            </p>
          </div>

          <div className="marquee">
            <ul className="marquee__content">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
            <ul aria-hidden="true" className="marquee__content">
              {placementCompanies && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
          </div>

          <div className="marquee-reverse">
            <ul className="marquee__content-reverse">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
            <ul aria-hidden="true" class="marquee__content-reverse">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
          </div>

        </div>
      </div>

        </div>
    )
}