import './HeroSection.scss'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";


export function HeroSection(props) {
    const navigate = useNavigate();
    const [typewriter, setTypeWriteContent] = useState(`Start Your Tech Journey, Where
    Human Talent Meets Generative AI. Our platform analyses resumes,
    organises skill matches, and moulds careers. Enter a World of
    Code-Driven Opportunities to Witness Your Future Take Shape.`)
    const content = [
        // Same substring at the start will only be typed out once, initially
        // "welcome to byteGPT",
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        typewriter,
    ];
    const searchSubmit = (e) => {
        e.preventDefault();
    };

    const handleRegister = () => {
        if (props.searchText == "") return;
        navigate("/register?email=" + props.searchText);
    };


    return (
        <div className='HeroSection'>
            <div className="home-title1">

                <img className="poster-img" src="/images/home-frame.jpg" alt="" />
                <p className="Heading"> The <span style={{ color: 'blue' }}> AI-powered </span>learning platform </p>

            </div>

            <div className="homepage-paragraph">

                <p className="paragraph">
                    Award-winning software trusted by industry-leading institutions. We collaborate with leading education providers and organisations

                    {/* <span className="learn-more">Learn more...</span> */}
                </p>

                <form className="search-btn" onSubmit={(event) => searchSubmit(event)}>
                    <input
                        style={{ fontSize: "20px", border: "none" }}
                        type="search"
                        placeholder="Enter your email"
                        onChange={(event) => props.setSearchText(event.target.value)}
                        value={props.searchText}
                    />
                    <button type="submit" onClick={handleRegister}>
                        Register
                    </button>
                </form>
            </div>

            <div className="company-details">
                <div className="company-description">
                    
                <p>
                    {typewriter}
                </p>
                    {/* <p>
                        {true ?
                            <TypeAnimation
                                sequence={content}
                                wrapper="span"
                                speed={50}
                                style={{ fontSize: "1.2em", display: "inline-block" }}
                                repeat={0}
                            /> : ''
                        }
                    </p> */}
                </div>
                <div className="company-video">
                    <video src="./videos/ai.mp4" controls></video>
                </div>
            </div>
        </div>

    );
}