import React from "react";
import Layouts from "./Layouts";
import "./MobileMaintainance.css";

const MobileMaintainance = () => {
  return (
    <div>
      <Layouts />
      <div className="mobile-maintainance">
        <div className="lock-img">
          <img src="/images/lock.svg" alt="" />
        </div>
        <div className="head-and-para">
          <h3>Login Successfully</h3>
          <p>Please use your desktop or download our app to continue.</p>
        </div>
        <div className="downlaod-btn">
          <img src="/images/play-store.svg" alt="" />
          <p>Download Our App</p>
        </div>
      </div>
    </div>
  );
};

export default MobileMaintainance;
