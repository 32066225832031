import React, { useEffect, useState } from "react";
import { BlogCard } from "../../Molecule/BlogCard/BlogsCard";

import './BlogsPage.css'
import { SearchComponent } from "./SearchComponent/SearchComponent";
const BlogsCardDetails = () => {
  const [blogs, setBlogs] = useState([]);


  return (
    <div className="blog-area">

      <SearchComponent  setBlogs={setBlogs}/>
      <div className="blog-cards-container">
        {blogs.map((blog) => (
          <BlogCard blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogsCardDetails;
