import React from "react";
import "./UnderMaintainance.css";

const UnderMaintainance = () => {
  return (
    <div style={{position: "relative", overflow:"hidden"}}>

        <div className="red-circle"></div>
        <div className="green-circle"></div>

      <div className="undermaintainance">
        <div className="image-and-heading">
          <img src="/images/Logo.svg" alt="" />
          <h3>We'll be back</h3>
          <p>
            We’re busy updating the Bytecoder for you Please check back soon
          </p>
        </div>

        <div className="robot-img">
          <img src="images/robot-img.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default UnderMaintainance;
