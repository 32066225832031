import React from "react";
import "./AboutUs.scss"

export function AboutUsDetails() {

    return (
        <div className="AboutUsDetails" >

            <div className="about-container">


                {/* about us details */}

                <div className="about-details">
                    <div className="details-head">
                        <h3 className="custom-underline">The Foundation</h3>
                    </div>
                    <div>
                        <p>
                            Bytecoder, a subsidiary of Oston Technology Pvt Ltd, is driven by
                            a rich history of innovation dating back to 2013. Oston
                            Technology, our parent company, has a remarkable track record with
                            over 15 patents and design registrations, showcasing our
                            commitment to product excellence and innovation. We eagerly seek
                            collaboration opportunities, offering concept ideation, product
                            design, and value engineering services.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Our Focus</h3>
                    </div>
                    <div>
                        <p>
                            Established in 2020, Bytecoder's mission is clear: connecting
                            talent with organizations. We bridge the gap between education and
                            industry needs, ensuring candidates are well-prepared for their
                            professional journeys. Our focus is on equal learning
                            opportunities, providing industry-relevant training, and fostering
                            essential soft skills.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">The Process</h3>
                    </div>
                    <div>
                        <p>
                            Our robust platform offers curated educational training and
                            mentorship programs to empower candidates. We manage the entire
                            hiring process, from pre-screening interviews to delivering
                            qualified candidates to organisations. We provide comprehensive
                            support, including placement assistance, exposure to industry
                            projects, and personalised interview preparations.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Solutions</h3>
                    </div>
                    <div>
                        <p>
                            At Bytecoder, we recognise that quality education and holistic
                            skill development are crucial in today's competitive world. We are
                            more than just a recruitment and education institute; we are a
                            transformative platform that helps candidates unlock their
                            potential and transcend limitations.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Join Us</h3>
                    </div>
                    <div>
                        <p>
                            Join us on this journey towards a brighter, more inclusive future
                            for aspiring professionals. Together, we'll shape fulfilling
                            careers that make a difference
                        </p>
                    </div>

                    <div className="computer-guy">
                        <img src="/images/computer-guy.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}