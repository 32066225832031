import React from "react";
import './Banner.scss'

export function Banner() {
    return (
        <div className="Banner">
            <div className="about-head">
                <div className="about">
                    <img className="heart" src="/images/heart.svg" alt="" />
                    <h2>About Us</h2>
                    <p>Get to know about everything about bytcoder</p>
                </div>
                <div className="about-images">
                    <div className="about-user-image">
                        <img src="/images/about-head-image.svg" alt="" />
                    </div>
                    <div className="color-frame">
                        <div className="coral-frame"></div>
                        <div className="skin-frame"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}