import React, { useEffect, useState } from "react";
import JobApplyCard from "./JobCard/JobApplyCard";
import "./JobApplyCard.css";
import * as JobOpenningsService from "../../service/JobsApiService";
import { Banner } from "./Banner/Banner";

export default function Careers() {
  const [jobOpennings, setJobOppenings] = useState([
    {
      id: 1,
      title: "Front-end Engineer",
      description:
        "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
      requirements: ["React", "Angular"],
      experience: "1-6 years",
    },
  ]);

  useEffect(() => {
    JobOpenningsService.getAllJobOpennings().then((opennings) => {
      setJobOppenings(opennings);
    });
  }, []);

  return (
    <div
      style={{
        background: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        fontFamily: "'Raleway', sans-serif",
        overflow: "hidden",
      }}
    >

      <Banner />

      <div className="cmpny-img-and-job">

        <div className="job-card" style={{ position: "relative" }}>
          <h5 style={{ textAlign: "start", fontSize: "16px" }}>
            We are Hiring for the below Roles:
          </h5>

          <div className="brain">
            <img src="/images/brain.svg" alt="" />
          </div>

          <div className="pen">
            <img src="/images/pen.svg" alt="" />
          </div>
          <div className="mail-envelope">
            <img src="/images/envelope.svg" alt="" />
          </div>

          <div className="notes">
            <img src="/images/notes.svg" alt="" />
          </div>

          {jobOpennings.map((jobDetails) => (
            <JobApplyCard
              title={jobDetails.title}
              requirements={jobDetails.requirements}
              experience={jobDetails.experience}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
