
export const COLOR_CODES = { primary: "#0F547E", secondary: "#28B2B6" };

export const Heading_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export const TEXT_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export function getButton_css(tab, selectedIcon) {
  return {
    color: tab.tag === selectedIcon ? "white" : "black",
    backgroundColor: tab.tag === selectedIcon ? "green" : "white",
  };
}

export const HEADERS = [
  { tag: "Home", path: "/home" },
  { tag: "Why Bytecoder", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
  { tag: "About Us", path: "/about-us" },
  // { tag: "login", path: "/login" }
];

export const FOOTERS = [
  { tag: "Home", path: "/home" },
  // { tag: "Services", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "About Us", path: "/about-us" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
];



// Home page


export const SERVICE_CARD_DETAILS = [
  {
    id: 1,
    title: "Personalized Learning Using AI",
    number: "01",
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Our advanced AI model, ByteGpt, assesses each candidate's performance and learning curve. It generates customised learning paths, enabling candidates to learn at their own pace. We provide subject-specific materials ranging from basic to advanced levels, based on each candidate's proficiency.ByteGpt continuously monitors performance and provides valuable feedback to aid improvement.",
  },
  {
    id: 2,
    title: "Comprehensive Mentorship",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",

    description:
      "Our mentorship programs focus on improving skills and knowledge for technical job interviews in various technology-related roles.These programs offer structured guidance, practice, and support to enhance a candidate's chances of success during the interview process.",
  },  
  {
    id: 3,
    title: "Self-Learning Courses",
    number: "03",
    imageUrl: "/images/temp/self-learning.avif",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  
  {
    id: 4,
    title: "Ai-Driven Candidates Portal",
    number: "04",
    imageUrl: "/images/temp/portal.jpeg",

    description:
      "ByteGpt empowers students to learn at their own pace.It creates adaptive learning paths based on individual progress and practice.ByteGpt tailors learning content based on the candidate's level of understanding and learning journey.ByteGpt continuously tracks candidate progress and provides precise feedback.We assist candidates in improving and achieving their targeted goals at their own pace.",
  },
  {
    id: 5,
    title: "Soft Skills, Communication, and Personality Development",
    number: "05",
    imageUrl: "/images/temp/softskills.jpeg",

    description:
      "In addition to technical training, we provide essential soft skills, communication skills, and critical thinking skills.We offer mock interviews, live industry projects, and other practical experiences to prepare students for the job market.",
  },
  {
    id: 6,
    title: "Internship and Live Projects",
    number: "06",
    imageUrl: "/images/temp/intership.webp",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  }, 
  {
    id: 7,
    title: "Interview preparetion and placements",
    number: "07",
    imageUrl: "/images/temp/interview.jpeg",

    description:
      "Our mentorship program offers a comprehensive understanding of industry-required skills.Experienced professionals guide candidates in their preparation, conduct mock interviews, assist with projects, and lead sessions on specific topics.We provide tailored support for interview preparation, enhancing candidates' confidence and readiness.",
  },
  {
    id: 8,
    title: "Best Fit Job for Candidates",
    number: "08",
    imageUrl: "/images/temp/job.jpeg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },

  {
    id: 9,
    title: "Refereces and Networking",
    number: "09",
    imageUrl: "/images/temp/society.jpg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },

  {
    id: 10,
    title: "AI Driven Assignments, Practices and Feedbacks ",
    number: "10",
    imageUrl: "/images/temp/AIAssignments.jpeg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  }, 
   {
    id: 11,
    title: "Resume and Portfolio Managment",
    number: "11",
    imageUrl: "/images/temp/portfolio.webp",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 12,
    title: "Job Portal",
    number: "12",
    imageUrl: "/images/temp/jobs.jpg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
];



const LandingPagetitle = "Get your dream job with bytecoder and AI"

const LandingPageDescription = " \
Bytecoder is an innovative edtech platform designed to empower both freshers and experienced software engineers by providing comprehensive \
support for technical learning , mentoring careerpath, interview preparation, projects and job placements.\
Leveraging the power of artificial intelligence, Bytecoder creates unique career paths, content and mentorship program based on candidates skills and level of experience.\
Bytecoder makes sure that all aspiring professionals have a brighter future in the tech industry.\
"

export const Home_DIV3_SERVICE = [
  {
    id: 1,
    title: "Personalized Learning",
    number: "01",
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Our advanced AI model, ByteGpt, assesses each candidate's performance and learning curve. It generates customised learning paths, enabling candidates to learn at their own pace. We provide subject-specific materials ranging from basic to advanced levels, based on each candidate's proficiency.ByteGpt continuously monitors performance and provides valuable feedback to aid improvement.",
  },
  {
    id: 2,
    title: "Comprehensive Mentorship",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",

    description:
      "Our mentorship programs focus on improving skills and knowledge for technical job interviews in various technology-related roles.These programs offer structured guidance, practice, and support to enhance a candidate's chances of success during the interview process.",
  },

  {
    id: 3,
    title: "Internship and Interview preparetion",
    number: "03",
    imageUrl: "/images/temp/portal.jpeg",

    description:
      "ByteGpt empowers students to learn at their own pace.It creates adaptive learning paths based on individual progress and practice.ByteGpt gives live projects to all candiddates based on thier skills and make sure candidates get job/intership in thier dream companies.Since its AI driven we can sure candidate is complately ready before going for interview.",
  },

  // {
  //   id: 4,
  //   title: "Interview preparetion and placements",
  //   number: "04",
  //   imageUrl: "/images/temp/interview.jpeg",

  //   description:
  //     "Our mentorship program offers a comprehensive understanding of industry-required skills.Experienced professionals guide candidates in their preparation, conduct mock interviews, assist with projects, and lead sessions on specific topics.We provide tailored support for interview preparation, enhancing candidates' confidence and readiness.",
  // },
  // {
  //   id: 5,
  //   title: "Best Fit Job for Candidates",
  //   number: "05",
  //   imageUrl: "/images/temp/personalised.jpg",
  //   description:
  //     "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  // },

  // {
  //   id: 8,
  //   title: "Refereces and Networking",
  //   number: "08",
  //   imageUrl: "/images/temp/society.jpg",
  //   description:
  //     "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  // },

  // {
  //   id: 9,
  //   title: "AI Driven Assignments, Practices and Feedbacks ",
  //   number: "09",
  //   imageUrl: "/images/temp/AIAssignments.jpeg",
  //   description:
  //     "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  // }, 
  //  {
  //   id: 9,
  //   title: "Resume and Portfolio Managment",
  //   number: "09",
  //   imageUrl: "/images/temp/portfolio.webp",
  //   description:
  //     "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  // },
  // {
  //   id: 10,
  //   title: "Job Portal",
  //   number: "10",
  //   imageUrl: "/images/temp/jobs.jpg",
  //   description:
  //     "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  // },
];


export const MentorsDetails = [
  {
    id: 0,
    name: "Sayar Choudhary",
    expertise: "Career mentorship",
    education: "IIT-Mumbai, India",
    areaOfInterest: "Team Management, Career Counselling, Enterprenaurship ",
    yearsOfExp: 10,
    image: "/images/CEO.jpg",
  },
  {
    id: 1,
    name: "Devesh Sadarangani",
    expertise: "Machine Learning Expert",
    education: "IIT-Delhi, India",
    areaOfInterest: "Python, Pandas, Machine Learning, Computer vision",
    yearsOfExp: 5,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/devesh.svg",
  },
  {
    id: 2,
    name: "Karan Sharma",
    expertise: "Devops Expert",
    education: "VIT-Vellore, India",
    areaOfInterest: "Machine Learning, Computer Vision ",
    yearsOfExp: 7,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/ganesh.svg",
  },
  {
    id: 3,
    name: "Ganesh Choudhary",
    expertise: "Backend Development Expert",
    education: "VIT-Vellore, India",
    areaOfInterest: "Java, Spring, Postgres, System Design, Kafka, Docker,",
    yearsOfExp: 5,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/ganesh.svg",
  }
];


