import React, { useEffect, useState } from "react";
import { BlogCard } from "../../../Molecule/BlogCard/BlogsCard";
import { useNavigate, Link } from "react-router-dom";
import * as BlogsService from "../../../service/BlogsApi";
import "./OurBlogs.scss"
export function OurBlogs() {

    const [blogs, setBlogs] = useState([]);



    useEffect(() => {
        BlogsService.getWebsiteBlogs("", "").then((response) => setBlogs(response?.response));
    }, [])


    return (
        <div className="OurBlogs">
            {/* Blog Area */}

            <div className="blog-area">
                <div>
                    <h1>Our Blogs</h1>
                </div>

                <div className="blog-desc">
                    <p style={{}}>
                        Elevating Your Journey from Education to Employment
                    </p>
                </div>

                <div className="blog-cards-container">
                    {blogs?.map((blog) => (
                        <BlogCard blog={blog} />
                    ))}
                </div>
            </div>
            <div className="all-blogs-btn">
                <Link style={{ color: "white" }} to="/blogs">
                    More Blogs
                </Link>
            </div>

        </div>
    )
}