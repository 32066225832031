import React, { useEffect, useState } from "react";
import "./Login.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import Layouts from '../MaintainancePages/Layouts'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {loginUser} from '../../service/LoginRegistrationApi'

export default function Login(props) {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(" ");

  const [isMobile, setMobile] = useState(false); // here we are checking that is mobile vide is active or not
  const navigate = useNavigate();

  const checkIsMobile = () => {
    // Determine whether it's mobile or desktop based on screen size
    setMobile(window.innerWidth <= 850);
  };

  useEffect(() => {
    // Check initially when the component mounts
    checkIsMobile();

    // Add event listener to listen for window resize events
    window.addEventListener("resize", checkIsMobile);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

 

  const handleSubmit = (e) => {
    // e.preventDefault();

    console.log("clicked!!!!!");
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    if(!emailRegex.test(userEmail)){
      setMessage("Please, enter a valid email!");
      return
    }

    if (userEmail == "" && password == "") {
      toast.error("please fill the credentials!!");
    } else if (userEmail == "") {
      toast.error("please enter the email");
    } else if (password == "") {
      toast.error("please enter the password");
    } else {
      setMessage("")
    const updatedData = {
      email: userEmail,
      password: password,
    };

    loginUser(updatedData).then(response=> response).then((response)=>{
      console.log(response)
      if(response.status==200){
        response.json().then(response=>
          {
            toast.success("you are successfully logged in.");
        if (isMobile) {
          navigate("/mobile-maintainance");
        } else{
          window.open(response.portalUrl, "_self")
        }
          }
          )
        
      }else{
        response.json().then(response=>
          {
            toast.success(response.detail);
          })

      }
    
    
    }).catch((e)=>{
      // toast.error("Please enter right credentials.");
      console.log("error" + e)
    })
   
  }

  };

  return (
    <div className="login-container">
      <Layouts />

      <div className="circle-one"></div>
      <div className="circle-two"></div>
      <div className="login-right-container">
        <div className="login-form-heading">
          <div>
            <h3>Log In</h3>
            <p className="login-custom-underline">
              Securely access your BYTECODER account.
            </p>
          </div>

          <div className="mobile-svg-image">
            <img src="/images/login-page-image.svg" alt="" />
          </div>
        </div>

        <div className="login-types">
          <form className="login-form">
            <div className="user-email">
              <label>
                Email <span className="asterisk">*</span>
              </label>
              
              <input
                value={userEmail}
                type="email"
                placeholder="example123@gmail.com"
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <div style = {{ color: "red" }}> {message} </div>
            </div>
            <div className="user-password">
              <label>
                Password <span className="asterisk">*</span>
              </label>
              <input
                value={password}
                type="password"
                placeholder=""
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="forget-and-login">
              <Link> Forget Password?</Link>

              <input
                type="button"
                placeholder="Login"
                value="Login"
                onClick={handleSubmit}
              />
              <ToastContainer />
            </div>
          </form>

          <div className="vertical-line"></div>

          <div className="or-and-lines">
            <div className="line1"></div>
            <div>
              <p>or</p>
            </div>
            <div className="line2"></div>
          </div>

          <div className="other-login-auth">
            <p className="sign-in-google">Sign In With Google</p>

            <Link>
              <div className="google-auth">
                <img src="/images/google.svg" alt="" />
                <span>
                  <p>Sign In with Google</p>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <p>
          New To ByteCoder <Link to={"/register"}>Sign Up Here</Link>{" "}
        </p>
      </div>
    </div>
  );
}

