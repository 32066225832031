import React, { useEffect, useState } from "react";
import "./ImpactSection.scss";




const profiles = [
  {
    name: "Ganesh Singh Choudhary",
    role: "helps folks break into Backend and Big Data Engineering",
    company: "Kaleidofin pvt ltd",
    location: "Bangalore",
    category:"Big Data",
    image:
      "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/ganesh.svg",
  },
  {
    name: "Deepak Dhaka",
    role: "helps folks break into AI/ML",
    company: "OpenAI",
    location: "Bangalore",
    category:"AI/ML",

    image:
    "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D",

  },
  {
    name: "Sayar Singh Choudhary",
    role: "helps folks break into Career",
    company: "Oston Technology",
    location: "Jaipur",
    category:"Career",

    image:
      "/images/CEO.jpg",
  },
  {
    name: "Devesh Sadarangani",
    role: "helps folks break into Product Design",
    company: "Oston Technology",
    location: "Jaipur",
    category:"Product",
    image:
    "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/devesh.svg",
  },
];

const categories = [
  "Career",
  "AI/ML",
  "Product",
  "Big Data",

];


function ImpactSection() {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [mentors, setMentors] = useState(profiles)


  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  useEffect(()=>{
    setMentors(profiles.filter(profile=> profile.category == selectedCategory))

  }, [selectedCategory])



  return (
    <div className="impact-section">
      <h1>
      Let Our Experts Take Your Career to the Higher Grounds
      </h1>
      <div className="container">

        <div className="chips">
          <ul>
            {categories.map((category) => (
              <li
                key={category}
                onClick={() => handleCategoryClick(category)}
                className={selectedCategory === category ? "active" : ""}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>




        <div className="card-container">
          {mentors.map((profile) => (
            <div className="card">
              <div
                className="profile-img"
                style={{ backgroundImage: `url(${profile.image})` }}
              ></div>
              <div className="profile-content">
                <h3>{profile.name}</h3>
                <p>{profile.role}</p>
                <p>
                  {profile.company},{profile.location}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImpactSection;
