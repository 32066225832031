
import React, { useEffect, useState } from "react";
import CourseCard from "../../../Molecule/CourseCard/CourseCard";
import { useNavigate, Link } from "react-router-dom";
import * as CoursesApi from "../../../service/CoursesApi";
import "./OurCourses.scss"

export function OurCourses() {

    const navigation = useNavigate();
    const [courses, setCourses] = useState([]);

    const openCourse = (code) => {
        navigation("/programs/" + code);
    };


    useEffect(() => {
        CoursesApi.getAllCourses("", "").then((cs) => setCourses(cs));
    }
        , [])

    return (
        <div className="OurCourses">
            <h2 className="courses-heading" style={{}}>
                Are You Ready to Accelerate Your Learning?
            </h2>
            <p className="courses-para">
                Diverse Courses for Every Aspiring Learner: Unlocking Knowledge and
                Skills Across Varied Disciplines
            </p>
            {courses.length > 0 &&
                <div>
                    <div className="program-card-container">

                        {courses.slice(0,6).map((course) => {
                            return (
                                <CourseCard
                                    course={course}
                                    openCourse={() => {
                                        openCourse(course.code);
                                    }}
                                />
                            );
                        })}

                    </div>

                </div>


            }
            <div className="all-courses-btn">
                <Link to={"/programs"}>
                    <button>View All Courses</button>
                </Link>
            </div>
        </div>


    )
}