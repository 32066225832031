
import * as Congs from './Configurations'

export function registerNewUser(userData)  {
    console.log("fetching shortcuts")

    return fetch(Congs.NEW_REGISTRATION, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch((e) => console.log("Error " + e));
  };

  export function loginUser(userData)  {
    console.log("logging user")

    return fetch(Congs.Login_USER, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          'Content-Type': 'application/json'
        }
      })
  };